import React, { useEffect, useState } from "react";
import banner_laptop_img from "../../assets/user/home/banner_laptop_img.svg";
import chart1 from "../../assets/user/AnimationGif/Chart1.gif";
import chart2 from "../../assets/user/AnimationGif/chart2.gif";
import { motion, AnimatePresence } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceIn,
  motionVariantsBounceOut,
  motionVariantsFadeIn,
  motionVariantsRight,
  motionVariantsTop,
} from "../common/animations";
import {Link} from 'react-router-dom'

const Banner = () => {
  const [chartValue, setChartValue] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setChartValue(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // Update motionVariantsZoomIn to include entrance and exit animations
  const motionVariantsZoomIn = {
    hidden: { opacity: 0, scale: 0.8, x: 50 },
    visible: { opacity: 1, scale: 1, x: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, scale: 0.8, x: 50, transition: { duration: 0.5 } },
  };

  // Define a new variant for the popup effect
  const motionVariantsPopup = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, scale: 0.5, transition: { duration: 0.5 } },
  };

  return (
    <div className="relative lg:px-16 md:px-10 px-5 py-10 bg-gradient-to-b from-[#ffff] to-[#FFD4C2] tracking-wide overflow-hidden">
      <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-0 gap-8">
        <div className="flex flex-col justify-between lg:gap-2 gap-4">
          <motion.p
            variants={motionVariantsRight}
            {...motionProperties}
            className="text-primaryLightOrange"
          >
            IT Services Designed
          </motion.p>
          <motion.h1
            variants={motionVariantsBounceOut}
            {...motionProperties}
            className="text-primaryTextColors xl:text-5xl md:text-4xl text-3xl font-[600] xl:w-[65%] leading-[1.2]"
          >
            Awesome IT <span className="text-primaryDarkOrange">Services</span> <br /> For Your Business
          </motion.h1>
          <motion.p
            variants={motionVariantsFadeIn}
            {...motionProperties}
            className="text-secondaryTextColor xl:w-[80%] xl:leading-[1.5] lg:leading-none leading-[2] tracking-wider"
          >
            WebBocket is a leading provider of innovative IT solutions aimed at
            helping businesses thrive in the digital age. With a team of highly
            skilled professionals and a commitment to excellence, we deliver
            cutting-edge technology solutions tailored to the unique needs of
            our clients.
          </motion.p>
          <div className="flex  md:flex-row flex-col-reverse gap-2">
            <Link to='/about-us' className="bg-gradient-to-r from-primaryDarkOrange to-primaryLightOrange hover:from-primaryLightOrange hover:to-primaryDarkOrange transition duration-200 ease-linear text-white px-8 py-2 rounded">
              Know More
            </Link>
            <div>
              <p className="text-primaryTextColor">Call us +91 6372833928</p>
              <p>For any question</p>
            </div>
          </div>
        </div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          variants={motionVariantsBounceIn}
          className="relative sm:h-[auto] h-[250px]"
        >
          <img src={banner_laptop_img} alt="banner_laptop_img" className="" />
          <AnimatePresence>
            {chartValue && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={motionVariantsZoomIn}
                {...motionProperties}
                className="md:h-40 h-auto md:w-40 w-28 md:p-0 p-2 bg-white absolute top-20 right-4 rounded-lg shadow-lg shadow-slate-500"
              >
                <img src={chart1} alt="chart1" />
              </motion.div>
            )}

            {chartValue && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={motionVariantsPopup}
                {...motionProperties}
                className="h-auto md:w-60 w-40 bg-white absolute top-40 right-92 md:p-4 p-2 rounded-lg shadow-xl shadow-slate-500 overflow-hidden"
              >
                <img src={chart2} alt="chart1" />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default Banner;
