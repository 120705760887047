import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const AdminNavbar = ({ openSidebar, setOpenSidebar }) => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSidebarToggle = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <div className="flex items-center justify-between border-b border-gray-200 px-8 py-2 shadow-xl">
      <button onClick={handleSidebarToggle}>
        {openSidebar ? (
          <MenuOpenIcon sx={{ fontSize: 40 }} />
        ) : (
          <ReadMoreIcon sx={{ fontSize: 40 }} />
        )}
      </button>
      <div className="border">
        <button
          id="basic-button"
          aria-controls={anchorEl ? "basic-menu" : undefined}
          aria-haspopup="true"
          onClick={handleMenuOpen}
          className="h-10 w-10 rounded-full bg-green-600 flex items-center justify-center border-2 border-black"
        >
          S
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            sx={{
              transition: "background-color 0.3s ease-in-out", // Add transition for hover effect
              "&:hover": {
                backgroundColor: "#1976D2",
                color:'white'
              },
            }}
            onClick={handleMenuClose}
          >
            Profile
          </MenuItem>
          <MenuItem
            sx={{
              transition: "background-color 0.3s ease-in-out", // Add transition for hover effect
              "&:hover": {
                backgroundColor: "#1976D2",
                color:'white'
              },
            }}
            onClick={handleMenuClose}
          >
            My account
          </MenuItem>
          <MenuItem
            sx={{
              transition: "background-color 0.3s ease-in-out", // Add transition for hover effect
              "&:hover": {
                backgroundColor: "#1976D2",
                
              },
            }}
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default AdminNavbar;
