import React, { useState } from "react";
import programmingBG from "../../assets/user/programming-background.png";
import img_faqs from "../../assets/user/service/img-faqs.png.png";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import website_de_icon from "../../assets/user/service/webisteDev_icon.svg";
import webiste_mentainace from "../../assets/user/service/webiste_mentainace.svg";
import onTime_service_icon from "../../assets/user/service/onTime_service_icon.svg";
import HeadingBanner from "../../components/common/HeadingBanner";
import video_bg from "../../assets/user/service/video_bg_off.png.png";
import { AiOutlineCheck, AiOutlineCheckCircle } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import servicesData from "../../components/data/service";
import { useParams } from "react-router-dom";
import ContactModal from "../../components/contac/ContackUsModal";

const faqs = [
  {
    id: "panel1",
    question: "First and foremost, you never want to go?",
    answer:
      "Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget maximus est, id dignissim quam.",
  },
  {
    id: "panel2",
    question: "How can I customize this theme?",
    answer:
      "Donec placerat, lectus sed mattis semper, neque lectus feugiat lectus, varius pulvinar diam eros in elit. Pellentesque convallis laoreet laoreet.",
  },
  {
    id: "panel3",
    question: "Can I cancel or change my order?",
    answer:
      "Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros, vitae egestas augue. Duis vel est augue.",
  },
  {
    id: "panel4",
    question: "How can I exchange an item bought online?",
    answer:
      "Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Nulla vitae elit libero, a pharetra augue.",
  },
];

const services = [
  {
    icon: website_de_icon,
    title: "Website Development",
    description:
      "We specialize in designing and developing custom websites that are optimized for performance and user experience.",
  },
  {
    icon: website_de_icon,
    title: "Web App Development",
    description:
      "We use modern technologies and frameworks to create robust applications that meet your specific business requirements.",
  },
  {
    icon: webiste_mentainace,
    title: "Website Maintenance",
    description:
      "We provide ongoing maintenance and support including security updates, performance optimization, and bug fixes.",
  },
  {
    icon: onTime_service_icon,
    title: "On Time Service",
    description:
      "Experience the peace of mind of on-time delivery with us delivering quality solutions, on time, every time, exceeding your expectations.",
  },
];

const ServiceDetails = () => {
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { id } = useParams();
  const serviceName = id.replace(/-/g, " ");
  console.log(serviceName);

  const filterService = servicesData?.find((ele) => ele?.title === serviceName);
  console.log(filterService);

  return (
    <div>
        <ContactModal show={showModal} handleClose={handleCloseModal} />
      <HeadingBanner
        path={`Home / Service / ${serviceName}`}
        title={serviceName}
      />

      <div className="py-10">
        {/* Container 1 */}
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:px-16 md:px-10 px-5 tracking-wider">
          <div>
            <h1 className="md:text-6xl text-3xl font-medium tracking-wider leading-[1.2]">
              {filterService?.title}
            </h1>
          </div>
          <div>
            <p className="text-secondaryTextColor leading-8 md:text-left text-justify">
              {filterService?.description}
            </p>
            <button onClick={handleShowModal} className="bg-gradient-to-r from-primaryDarkOrange mt-4 to-primaryLightOrange hover:from-primaryLightOrange hover:to-primaryDarkOrange transition duration-200 ease-linear text-white px-8 py-2 rounded">
              Request A Call
            </button>
          </div>
        </div>

        {/* Container 2 */}
        <div className="grid xl:grid-cols-2 grid-cols-1 gap-8 mt-16 lg:px-16 md:px-10 px-5 tracking-wide">
          <div className="">
            <img
              src={filterService?.image}
              alt="banner_laptop_img"
              className="w-full rounded-xl"
            />
          </div>

          <div>
            <h3 className="text-3xl font-semibold">What we Provide</h3>

            <div className="grid md:grid-cols-2 grid-cols-1 gap-8 mt-8">
              {filterService?.whatWeProvide?.map((service, index) => (
                <div key={index} className="flex flex-col gap-2">
                  <img src={service?.icon} alt="img" className="w-10" />
                  <h6 className="text-lg font-semibold">{service?.title}</h6>
                  <p className="text-sm text-[#6E6E6E] leading-6 tracking-wider">
                    {service?.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Container 3 */}
        <div className="flex lg:flex-row flex-col py-10 lg:pr-16 md:pr-10 pr-5 tracking-wider">
          <div className="flex">
            <img
              src={img_faqs}
              alt="avtar_img"
              className="h-[300px] md:w-[20%]"
            />
            <div className="w-[80%] md:mt-20">
              <div className="flex flex-col md:gap-4 gap-2">
                <p className="text-primaryLightOrange text-lg">
                  Get Your Answer Fast
                </p>
                <p className="md:text-4xl text-3xl font-normal capitalize">
                  Everything you need to
                </p>
                <p className="md:text-4xl text-3xl font-semibold capitalize">
                  Know to work better
                </p>
                <p className="text-secondaryTextColor text-sm w-[90%]">
                  Hiring an expert could well prove to be a shot in the arm for
                  your online business, that deserves a robust web presence.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:pl-16 md:pl-10 pl-5 ">
            {filterService?.faq?.map((faq) => (
              <div className="shadow mb-4">
                <Accordion
                  key={faq.id}
                  expanded={expanded === faq.id}
                  onChange={handleChange(faq.id)}
                >
                  <AccordionSummary
                    sx={{
                      background: expanded === faq.id ? "#FC7952" : "inherit",
                      borderRadius: "5px",
                    }}
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: expanded === faq.id ? "#FFFFFF" : "inherit",
                        }}
                      />
                    }
                    aria-controls={`${faq.id}-content`}
                    id={`${faq.id}-header`}
                  >
                    <Typography sx={{ width: "80%", flexShrink: 0 }}>
                      {faq?.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq?.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </div>

        {/* container 4 */}
        <div className="lg:px-16 md:px-10 px-5">
          <div className="py-5 px-8 flex lg:flex-row flex-col gap-2 rounded-md justify-between items-center border shadow">
            <div>
              <p className="md:text-le text-center font-semibold ">
                Do you want to Book Service , and want to connect with us !
              </p>
            </div>
            <div className="flex md:flex-row flex-col gap-2">
              <button className="bg-gradient-to-r from-primaryDarkOrange to-primaryLightOrange hover:from-primaryLightOrange hover:to-primaryDarkOrange transition duration-200 ease-linear text-white px-8 py-2 rounded">
                Get A Free Quote
              </button>
              <button className="py-2 px-4 bg-[#4B58F9] rounded text-white">
                Contact us
              </button>
            </div>
          </div>
        </div>

        {/* container 5 */}
        <div className="flex flex-col justify-center mt-10 lg:px-16 md:px-10 px-5 gap-4">
          <h2 className="md:text-4xl text-2xl font-semibold  text-center">
            {filterService?.title}
          </h2>
          <p className="md:w-[70%] m-auto text-center text-secondaryTextColor ">
            We help transform your brand into a visual story. We turn your
            website into a powerful way to engage with your audience and a
            high-performing marketing tool for your enterprise.
          </p>
        </div>

        {/* container 6 */}
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:px-16 md:px-10 px-5  mt-10 tracking-wider">
          <div className=" flex items-center">
            <div>
              <h2 className="text-primaryLightOrange text-xl font-semibold capitalize">
                About our services
              </h2>

              <div className="mt-6">
                <p className="text-base text-[#6E6E6E]">
                  {filterService?.AboutService}
                </p>
              </div>
              <div className=" flex flex-col gap-3 mt-8">
                {filterService?.AboutServiceKeyPoint?.map((ele) => (
                  <p className="flex gap-2">
                    <FaCheck className="mt-1 text-primaryLightOrange" />
                    {ele}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-start overflow-hidden">
            <img
              src={video_bg}
              alt="avtar_img"
              className="w-full lg:scale-[1] scale-[1.3]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
