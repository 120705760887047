import React from 'react';
// import DashboardLayout from '../layouts/DashboardLayout';

const Dashboard = () => (
    // <DashboardLayout>
        <h1>Dashboard</h1>
    // </DashboardLayout>
);

export default Dashboard;
