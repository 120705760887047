import React from "react";
import kubernetes from "../../assets/user/home/kubernetes.png";
import docker from "../../assets/user/home/docker_logo.png";
import jira from "../../assets/user/home/jira-software (2).png";
import prometheus from "../../assets/user/home/prometheus (2).png";
import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceIn,
  motionVariantsBounceOut,
} from "../common/animations";

const Devops = () => {
  const images = [
    { src: kubernetes, alt: "kubernetes" },
    { src: docker, alt: "docker" },
    { src: jira, alt: "jira" },
    { src: prometheus, alt: "prometheus" },
  ];

  return (
    <div className="flex flex-wrap justify-center gap-16">
      {images.map((image, index) => (
        <div key={index} className="">
          <motion.img
            variants={
              index % 2 == 0 ? motionVariantsBounceIn : motionVariantsBounceOut
            }
            {...motionProperties}
            src={image.src}
            alt={image.alt}
            className="w-[8rem] h-[7rem] object-contain"
          />
        </div>
      ))}
    </div>
  );
};

export default Devops;
