import React from 'react'
import TechnologyNews from '../../components/home/TechnologyNews';

const Blogs = () => {
  return (
    <div>
        <TechnologyNews/>
    </div>
  )
}

export default Blogs;