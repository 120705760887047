import React from "react";
import mySql from "../../assets/user/home/mySquil.svg";
import postgre from "../../assets/user/home/images-removebg-preview.png";
import mongo from "../../assets/user/home/mongo.svg";
import aws from "../../assets/user/home/aws-hd-logo (2).png";
import oracle from "../../assets/user/home/oracle_logo (2).png";
import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceIn,
  motionVariantsBounceOut,
} from "../common/animations";

const Database = () => {
  const images = [
    { src: mySql, alt: "MySql" },
    { src: postgre, alt: "Postgre" },
    { src: mongo, alt: "MongoDB" },
    { src: aws, alt: "Aws" },
    { src: oracle, alt: "Oracle" },
  ];
  return (
    <div className="flex flex-wrap justify-center gap-16">
      {images.map((image, index) => (
        <motion.div
          variants={
            index % 2 == 0 ? motionVariantsBounceIn : motionVariantsBounceOut
          }
          {...motionProperties}
          key={index}
          className=""
        >
          <img
            src={image.src}
            alt={image.alt}
            className="w-[8rem] h-[7rem] object-contain"
          />
        </motion.div>
      ))}
    </div>
  );
};

export default Database;
