import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Sidebar = ({ setOpenSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (path) => {
    navigate(path);
    if (window.innerWidth < 769) {
      setOpenSidebar(false);
    }
  };

  return (
    <div className="w-60" aria-label="Sidebar">
      <div className="overflow-y-auto py-4 px-3 bg-[#165f71] h-screen">
        <Link to="/dashboard" onClick={() => handleClick('/dashboard')}>
          <p className="text-lg font-semibold text-white">Logo</p>
        </Link>
        <ul className="space-y-2 mt-4">
          <li>
            <Link
              to="/dashboard"
              onClick={() => handleClick('/dashboard')} 
              className={`flex items-center p-2 text-base font-normal rounded-lg hover:bg-gray-100 hover:text-black dark:hover:bg-gray-700 ${
                location.pathname === '/dashboard' ? 'bg-gray-200 text-gray-900' : 'text-white'
              }`}
            >
              <p>Dashboard</p>
            </Link>
          </li>
          <li>
            <Link
              to="/setting"
              onClick={() => handleClick('/settings')}
              className={`flex items-center p-2 text-base font-normal rounded-lg hover:bg-gray-100 hover:text-black dark:hover:bg-gray-700 ${
                location.pathname === '/setting' ? 'bg-gray-200 text-gray-900' : 'text-white'
              }`}
            >
              <p>Settings</p>
            </Link>
          </li>
          
          {/* Add more links as needed */}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
