import React from "react";
import mission from "../../assets/user/about/mission.jpeg";

const MissionVission = () => {
  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
      <div>
        <img src={mission} alt="Mission Image" className="rounded-xl" />
      </div>
      <div>
        <div>
          <h1 className="text-3xl font-medium">Our Mission</h1>
          <div className="h-1 bg-gradient-to-r from-primaryDarkOrange to-white w-[30%] my-2"></div>
          <p className="mt-4 text-secondaryTextColor tracking-wide text-justify">
            At Web_Bocket, we envision a future where innovation knows no bounds
            and technological solutions are harnessed to their fullest
            potential. Our vision is clear - to become the premier software
            company within our state, serving as the catalyst for digital
            transformation and progress.
          </p>
        </div>
        <div className="mt-8">
          <h1 className="text-3xl font-medium">Our Vision</h1>
          <div className="h-1 bg-gradient-to-r from-primaryDarkOrange to-white w-[30%] my-2"></div>
          <p className="mt-4 text-secondaryTextColor tracking-wide text-justify">
            Web_Bocket provides the best customer results possible and delivers
            the WOW factor through our services. Our mission is to be the expert
            in providing student training & internship and let them placed in a
            good companies. And we are here to support and achieve a good
            carrier to bright their future.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MissionVission;
