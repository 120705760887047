import React from "react";
import HeadingBanner from "../../components/common/HeadingBanner";
import AboutCompany from "../../components/about/AboutCompany";
import CountSection from "../../components/home/CountSection";
import OurTeam from "../../components/about/OurTeam";
import WhoWeAre from "../../components/about/WhoWeAre";
import EmailBanner from "../../components/home/EmailBanner";
import MissionVission from "../../components/about/MissionVission";

const AboutUs = () => {
  return (
    <div>
      <HeadingBanner path="Home / About Us" title="About Us" />
      <div className="lg:px-16 md:px-10 px-5">
        <AboutCompany />
      </div>
      <CountSection />
      <div className="lg:px-16 md:px-10 px-5 py-10">
        <OurTeam />
        <WhoWeAre />
        <MissionVission/>
      </div>
      <EmailBanner />
    </div>
  );
};

export default AboutUs;
