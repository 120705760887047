// src/components/OurClients.js

import React from "react";
import iber from '../../assets/user/client2/iber.svg';
import studit from '../../assets/user/client2/studit.svg';
import winning from '../../assets/user/client2/winning_wavez.svg';
import kayaspa from '../../assets/user/client2/kayaSpa.svg';
import kgs from '../../assets/user/client2/KgsTech.svg';
import weekend from '../../assets/user/client2/weekendBhraman.svg';
import airgon from '../../assets/user/client2/airgon.svg';
import techmentee from '../../assets/user/client2/techmentee.svg';
import kaffeinn from '../../assets/user/client2/kaffeinn.svg';
import wrip from '../../assets/user/client2/wrip.svg';
import assavio from '../../assets/user/client2/assavio.svg';
import bbsr from '../../assets/user/client2/bbsr_service.svg';
import jyoti from '../../assets/user/client2/jyoti.svg';
import gift from '../../assets/user/client2/gift.svg';
import wbc from '../../assets/user/client2/wbc.svg';
import ImageSlider  from '../common/ImageSlider'

const OurClients = () => {
  const clients = [
    { src: iber, alt: 'Iber' },
    { src: studit, alt: 'Studit' },
    { src: winning, alt: 'Winning Wavez' },
    { src: kayaspa, alt: 'Kaya Spa' },
    { src: kgs, alt: 'KGS Tech' },
    { src: weekend, alt: 'Weekend Bhraman' },
    { src: airgon, alt: 'Airgon' },
    { src: techmentee, alt: 'Techmentee' },
    { src: kaffeinn, alt: 'Kaffeinn' },
    { src: wrip, alt: 'Wrip' },
    { src: assavio, alt: 'Assavio' },
    { src: bbsr, alt: 'BBSR Service' },
    { src: jyoti, alt: 'Jyoti' },
    { src: gift, alt: 'Gift' },
    { src: wbc, alt: 'WBC' },
  ];

  return (
    <div className="lg:px-16 md:px-10 px-5 py-5">
      <div className="tracking-wider flex flex-col gap-4">
        <p className="text-center text-primaryLightOrange">Our Services</p>
        <h1 className="lg:text-4xl md:text-2xl text-xl text-center text-primaryTextColor">
          Who is growing with Us
        </h1>
        <p className="text-center m-auto text-sm lg:w-[60%] text-secondaryTextColor">
        Discover the thriving network of partners, clients, and entrepreneurs who are achieving remarkable growth and progress through their collaboration with us.
        </p>
      </div>
      
      <ImageSlider images={clients} />
    </div>
  );
};

export default OurClients;
