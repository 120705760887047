import React from "react";
import HeadingBanner from "../../components/common/HeadingBanner";
import website_icon from "../../assets/user/service/website_icon.svg";
import android_icon from "../../assets/user/service/android_icon.svg";
import ui_us_icon from "../../assets/user/service/ui_us_icon.svg";
import crm_icon from "../../assets/user/service/crm_icon.svg";
import pos_icon from "../../assets/user/service/pos_icon.svg";
import building_icon from "../../assets/user/service/building_icon.svg";
import servicesData from "../../components/data/service";

import { MdCheckCircle } from "react-icons/md";
import GetInTouchForm from "../../components/services/GetInTouchForm";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceIn,
  motionVariantsLeft,
  motionVariantsRight,
  motionVariantsRotateIn,
  motionVariantsScaleDown,
} from "../../components/common/animations";

const services = [
  {
    id: 1,
    imgSrc: website_icon,
    title: "Website & Webapp Development",
    description:
      "At WebBocket, we offer top-notch web development services that cater to the unique needs of businesses of all sizes.",
  },
  {
    id: 2,
    imgSrc: android_icon,
    title: "Android & iOS App Development",
    description:
      "Unlock the power of user-centric design with our UI/UX expertise, tailored to bring your software to life and drive user engagement.",
  },
  {
    id: 3,
    imgSrc: ui_us_icon,
    title: "Ui/Ux Design",
    description:
      "At WebBocket, we offer top-notch web development services that cater to the unique needs of businesses of all sizes.",
  },
  {
    id: 4,
    imgSrc: crm_icon,
    title: "CRM Software",
    description:
      "At WebBocket, we offer top-notch web development services that cater to the unique needs of businesses of all sizes.",
  },
  {
    id: 5,
    imgSrc: pos_icon,
    title: "POS Software",
    description:
      "At WebBocket, we offer top-notch web development services that cater to the unique needs of businesses of all sizes.",
  },
  {
    id: 6,
    imgSrc: building_icon,
    title: "Billing Software",
    description:
      "At WebBocket, we offer top-notch web development services that cater to the unique needs of businesses of all sizes.",
  },
];

const serviceFeatures = [
  {
    id: 1,
    title: "High Performance",
    description:
      "Experience unparalleled performance with our optimized solutions designed to handle your toughest challenges effortlessly.",
  },
  {
    id: 2,
    title: "Customer Support",
    description:
      "Our dedicated support team is here to ensure you receive prompt and efficient assistance whenever you need it.",
  },
  {
    id: 3,
    title: "Media Marketing",
    description:
      "Boost your online presence and engagement with our comprehensive media marketing strategies tailored for success.",
  },
  {
    id: 4,
    title: "Network Protection",
    description:
      "Safeguard your network with our advanced protection services, designed to keep your data secure at all times.",
  },
  {
    id: 5,
    title: "Content Strategy",
    description:
      "Enhance your brand with a robust content strategy that captivates your audience and drives meaningful engagement.",
  },
  {
    id: 6,
    title: "Technology",
    description:
      "Leverage cutting-edge technology to stay ahead in the industry with our innovative and future-proof solutions.",
  },
];

const Services = () => {
  const navigate = useNavigate();
  return (
    <div>
      <HeadingBanner path="Home / Services" title="Our Services" />
      <div className="lg:px-16 md:px-10 px-5 py-5 overflow-hidden">
        <div className="tracking-wider flex flex-col gap-4">
          <p className="text-center text-primaryLightOrange">
            Services We Deliver
          </p>
          <motion.h1
            variants={motionVariantsLeft}
            {...motionProperties}
          className="lg:text-4xl md:text-2xl text-xl text-center text-primaryTextColor">
            Preparing For Your Success
          </motion.h1>
          <motion.p
            variants={motionVariantsBounceIn}
            {...motionProperties}
          className="text-center m-auto text-sm lg:w-[60%] text-secondaryTextColor">
            This letter expresses our sincere gratitude for the excellent work
            that your company has done. I would like to note the high
            professionalism of the entire team, the ability to work with the
            customer, taking into account all his wishes.
          </motion.p>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 gap-y-16 mt-8 md:justify-items-start justify-items-center">
          {servicesData.map((service) => (
            <div key={service.id} className="flex flex-col gap-4">
              <motion.img
                variants={motionVariantsRotateIn}
                {...motionProperties}
                src={service?.icon}
                alt="img"
                className="w-10 md:m-0 m-auto"
              />
              <motion.h6
                variants={motionVariantsBounceIn}
                {...motionProperties}
                className="text-lg font-semibold md:text-left text-center"
              >
                {service.title}
              </motion.h6>
              <motion.p
                variants={motionVariantsLeft}
                {...motionProperties}
                className="text-sm text-[#6E6E6E] leading-6 tracking-wider w-[80%] md:m-0 m-auto text-justify line-clamp-4"
              >
                {service.description}
              </motion.p>

              <motion.button
                variants={motionVariantsScaleDown}
                {...motionProperties}
                onClick={() =>
                  navigate(`/services/${service?.title.replace(/\s+/g, "-")}`)
                }
                className="w-[150px] md:m-0 m-auto py-2 border-2 border-primaryLightOrange rounded text-primaryLightOrange hover:bg-primaryLightOrange hover:text-white"
              >
                Know More
              </motion.button>
            </div>
          ))}
        </div>

        <div className="tracking-wider flex flex-col gap-4 mt-16">
          <p className="text-center text-primaryLightOrange">
            We can Help You Succeed
          </p>
          <motion.h1
            variants={motionVariantsBounceIn}
            {...motionProperties}
          className="lg:text-4xl md:text-2xl text-xl text-center text-primaryTextColor">
            Impressive Pragmatic & Helpful Features Included
          </motion.h1>
          <motion.p
            variants={motionVariantsRight}
            {...motionProperties}
          className="text-center m-auto text-sm lg:w-[60%] text-secondaryTextColor">
            This letter expresses our sincere gratitude for the excellent work
            that your company has done. I would like to note the high
            professionalism of the entire team, the ability to work with the
            customer,
          </motion.p>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-10 ">
          {serviceFeatures.map((feature) => (
            <div key={feature.id} className="flex gap-2">
              <div>
                <MdCheckCircle
                  size={20}
                  className="mt-[2px] text-primaryLightOrange"
                />
              </div>
              <div>
                <h1 className="font-medium">{feature.title}</h1>
                <p className="text-sm tracking-wider leading-7 mt-2 md:text-left text-justify">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <GetInTouchForm />
      </div>
    </div>
  );
};

export default Services;
