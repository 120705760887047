import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import logo from "../../assets/user/logo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdArrowDropdown } from "react-icons/io";

const menuItems = [
  { name: "Home", path: "/" },
  { name: "About Us", path: "/about-us" },
  { name: "Services", path: "/services" },
  {
    name: "Products",
    path: "#",
    submenu: [
      { name: "Hostel Management System", path: "/" },
      { name: "Attendance System", path: "/" },
    ],
  },
  // { name: "Career", path: "/" },
  { name: "Blogs", path: "/blogs" },
];

const Navbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const path = useLocation()?.pathname;
  const navigate = useNavigate();

  // const [showModal, setShowModal] = useState(false);

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  // const handleShowModal = () => {
  //   setShowModal(true);
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleSubMenu = (index) => {
    setOpenSubMenu((prevState) => (prevState === index ? null : index));
  };

  return (
    <React.Fragment>
      {/* desktop */}
      <nav className="lg:contents hidden">
        <div className="flex justify-between items-center px-16 py-2 shadow">
          <div>
            <img src={logo} alt="logo" className="w-16" />
          </div>
          <div className="flex gap-10">
            {menuItems.map((item, index) => (
              <div key={index} className="relative">
                <Link
                  to={item.path}
                  className="hover:text-primaryDarkOrange transition duration-200 ease-linear flex items-center"
                  onClick={() => item.submenu && toggleSubMenu(index)}
                >
                  {item.name}
                  {item?.submenu && <IoMdArrowDropdown />}
                </Link>
                {item.submenu && (
                  <div
                    className={`absolute z-20 top-full left-0 mt-2 w-[250px] bg-white shadow-lg rounded-md transition-all duration-300 ease-in-out transform ${
                      openSubMenu === index
                        ? "opacity-100 translate-y-0"
                        : "opacity-0 translate-y-4"
                    }`}
                  >
                    {item.submenu.map((subItem, subIndex) => (
                      <Link
                        key={subIndex}
                        to={subItem.path}
                        className="block px-4 py-2 hover:bg-primaryLightOrange hover:text-white transition duration-200 ease-linear"
                      >
                        {subItem.name}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div>
            <button
              onClick={() => navigate("/contact-us")}
              className="bg-gradient-to-r from-primaryDarkOrange to-primaryLightOrange hover:from-primaryLightOrange hover:to-primaryDarkOrange transition duration-200 ease-linear text-white px-8 py-2 rounded"
            >
              Contact Us
            </button>
          </div>
        </div>
      </nav>
      {path === "/" && (
        <nav className="bg-gradient-to-r from-primaryDarkOrange to-primaryExtraLightOrange py-1 text-center text-white lg:flex justify-center hidden">
          <p>WEB _ BOCKET SOFTWARE PVT. LTD“ Your Success is our Purpose ”</p>
        </nav>
      )}

      {/* mobile */}
      <nav
        className={`px-8 py-4 bg-white lg:hidden flex justify-between items-center ${
          isFixed ? "fixed top-0 left-0 right-0 z-50" : ""
        }`}
      >
        <img src={logo} alt="logo" className="w-10" />
        <button
          onClick={() => setOpenMenu(!openMenu)}
          className="focus:outline-none bg-primaryLightOrange rounded-full p-2"
        >
          <GiHamburgerMenu size={25} color="white" />
        </button>
      </nav>

      <div
        className={`transition-transform duration-300 ease-in-out transform ${
          openMenu ? "translate-x-0" : "translate-x-full"
        } fixed top-0 right-0 h-full w-[70%] bg-gray-200 border z-50 shadow-2xl`}
      >
        <div className="flex items-center justify-between py-4 px-10 border-b">
          <img src={logo} alt="logo" className="w-10" />
          <button
            onClick={() => setOpenMenu(!openMenu)}
            className="focus:outline-none"
          >
            Close
          </button>
        </div>

        <div className="flex flex-col py-4 px-10 space-y-4">
          {menuItems.map((item, index) => (
            <div key={index}>
              <Link
                to={item?.path}
                onClick={() => {
                  !item?.submenu && setOpenMenu(false);
                  item.submenu && toggleSubMenu(index);
                }}
                className="hover:text-primaryDarkOrange transition duration-200 ease-linear text-xl flex items-center"
              >
                {item.name}
                {item?.submenu && <IoMdArrowDropdown />}
              </Link>
              {item.submenu && (
                <div
                  className={`mt-2 bg-white shadow-md rounded-md transition-all duration-300 ease-in-out transform ${
                    openSubMenu === index
                      ? "opacity-100 max-h-screen translate-y-0"
                      : "opacity-0 max-h-0 translate-y-4"
                  } overflow-hidden`}
                >
                  {item.submenu.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subItem.path}
                      className="block px-4 py-2 hover:bg-gray-100 transition duration-200 ease-linear"
                    >
                      {subItem.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
          <button
          onClick={() =>{
            setOpenMenu(false)
            navigate("/contact-us")}}
          className="bg-gradient-to-r from-primaryDarkOrange to-primaryLightOrange text-white px-4 py-2 rounded">
            Contact Us
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
