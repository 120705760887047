import React from "react";
import BlogImage from "../../assets/user/home/BlogImage.svg";
import blog_img1 from "../../assets/user/home/blog_img1.svg";
import blog_img2 from "../../assets/user/home/blog_img2.svg";
import blog_img3 from "../../assets/user/home/blog_img3.svg";
import {motion} from 'framer-motion'
import { motionProperties, motionVariantsBounceIn, motionVariantsBounceOut, motionVariantsFadeIn, motionVariantsFadeOut, motionVariantsLeft, motionVariantsRight, motionVariantsRotateIn, motionVariantsZoomIn } from "../common/animations";

const TechnologyNews = () => {
  return (
    <div className="lg:px-16 md:px-10 px-5 py-5 overflow-hidden">
      <motion.div
      variants={motionVariantsLeft}
           {...motionProperties}
      className="tracking-wider flex flex-col gap-4">
        <p className="text-center text-primaryLightOrange">Technology News</p>
        <h1 className="lg:text-4xl md:text-2xl text-xl text-center text-primaryTextColor">
          Latest Blogs
        </h1>
        <p className="text-center m-auto text-sm lg:w-[60%] text-secondaryTextColor">
          With our measurable ease of use, ad hoc reporting or self-serve
          modeling, we help finance pros reach new heights of influence and
          importance. I'ts no wonder our customers aren't just satisfied using
          love it. .
        </p>
      </motion.div>

      <div className="grid lg:grid-cols-2 grid-cols-1 mt-8 gap-8 tracking-wider">
        <motion.div
         variants={motionVariantsZoomIn}
         {...motionProperties}
        >
          <img src={BlogImage} alt="Blog Image" />
          <div className="flex flex-col gap-2">
            <p className="text-secondaryTextColor text-sm">
              Post On November 05, 2023 | By Ramesh
            </p>
            <h1 className="text-primaryTextColor font-medium text-xl">
              5 Steps Forming a Tax-Exempt Nonprofit Corporation.
            </h1>
            <p className="text-secondaryTextColor">
            Five simple steps to establish your tax-exempt nonprofit corporation, from defining your mission to securing federal tax-exempt status, ensuring legal compliance and effective governance
            </p>
          </div>
        </motion.div>
        <div className="flex flex-col justify-between gap-4">
          <motion.div
           variants={motionVariantsRight}
           {...motionProperties}
          className="flex md:flex-row flex-col md:gap-8 gap-4 md:border-none border border-gray-200 md:p-0 p-4 rounded-xl">
            <div className="md:w-[30%]">
              <img src={blog_img1} alt="blog image " className="w-full" />
            </div>
            <div className="md:w-[70%] flex items-center">
              <div className="flex flex-col gap-2">
                <p className="text-secondaryTextColor text-sm">
                  Post On January 07, 2024 | By Peeyush
                </p>
                <h1 className="text-primaryTextColor md:text-xl">
                Unlocking Business Potential: The Power of Custom Software Solutions
                </h1>
              </div>
            </div>
          </motion.div>
          <motion.div
           variants={motionVariantsLeft}
           {...motionProperties}
          className="flex md:flex-row flex-col md:gap-8 gap-4 md:border-none border border-gray-200 md:p-0 p-4 rounded-xl">
            <div className="md:w-[30%]">
              <img src={blog_img2} alt="blog image " className="w-full" />
            </div>
            <div className="md:w-[70%] flex items-center">
              <div className="flex flex-col gap-2">
                <p className="text-secondaryTextColor text-sm">
                  Post On January 07, 2020 | By Ramesh
                </p>
                <h1 className="text-primaryTextColor md:text-xl">
                Navigating the Future: Trends Shaping the Software Development Landscape
                </h1>
              </div>
            </div>
          </motion.div>
          <motion.div
           variants={motionVariantsRight}
           {...motionProperties}
          className="flex md:flex-row flex-col md:gap-8 gap-4 md:border-none border border-gray-200 md:p-0 p-4 rounded-xl">
            <div className="md:w-[30%]">
              <img src={blog_img3} alt="blog image " className="w-full" />
            </div>
            <div className="md:w-[70%] flex items-center">
              <div className="flex flex-col gap-2">
                <p className="text-secondaryTextColor text-sm">
                  Post On March 22, 2024 | By Ramesh
                </p>
                <h1 className="text-primaryTextColor md:text-xl">
                Enhancing User Experience: Best Practices for UI/UX Design in Software Development
                </h1>
              </div>
            </div>
          </motion.div>
          <motion.div
           variants={motionVariantsLeft}
           {...motionProperties}
          className="flex md:flex-row flex-col md:gap-8 gap-4 md:border-none border border-gray-200 md:p-0 p-4 rounded-xl">
            <div className="md:w-[30%]">
              <img src={blog_img1} alt="blog image " className="w-full" />
            </div>
            <div className="md:w-[70%] flex items-center">
              <div className="flex flex-col gap-2">
                <p className="text-secondaryTextColor text-sm">
                  Post On May 06, 2024 | By Peeyush
                </p>
                <h1 className="text-primaryTextColor md:text-xl">
                Harnessing the Power of Agile: Accelerating Software Development in Today's Dynamic Environment
                </h1>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default TechnologyNews;
