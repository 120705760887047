import React from "react";
import letsTalk from "../../assets/user/home/letsTalk_bg.svg";

const EmailBanner = () => {
  return (
    <div className="lg:px-16 md:px-10 px-5 py-5">
      <div
        style={{
          backgroundImage: `url(${letsTalk})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="md:h-[300px] h-[200px] rounded-2xl flex items-center justify-center text-white text-center"
      >
        <div className="flex flex-col md:gap-8 gap-4">
          <p className="md:text-xl text-sm">Want to work with us? Let’s talk</p>
          <h1 className="lg:text-5xl md:text-3xl text-2xl">web.bocket@gmail.com</h1>
          <button className="bg-gradient-to-r w-[150px] m-auto from-primaryDarkOrange to-primaryLightOrange hover:from-primaryLightOrange hover:to-primaryDarkOrange transition duration-200 ease-linear text-white px-8 py-2 rounded">
            Know More
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailBanner;
