import React from 'react'
import getInTouchForm_img from '../../assets/user/service/getInTouchForm_img.svg'

const GetInTouchForm = () => {
  return (
    <div className='grid lg:grid-cols-2 md:grid-cols-1 mt-8'>
        <div>
            <img src={getInTouchForm_img} alt="getInTouchForm_img" className='lg:w-[80%]'/>
        </div>
        <div>
            <p className='text-primaryLightOrange'>Get in Touch</p>
            <h1 className='md:text-4xl text-xl'>Need Help For Services ?</h1>

            <form action="" className='flex flex-col gap-4 mt-4'>
                <input type="text" placeholder='Name' required  className='border border-gray-300 w-full px-6 py-2 rounded'/>
                <input type="text" placeholder='Email' required className='border border-gray-300 w-full px-6 py-2 rounded'/>
                <textarea rows={8} type="text" placeholder='Message' required className='border border-gray-300 w-full px-6 py-2 rounded'/>
                <button className='text-white bg-primaryLightOrange hover:bg-primaryDarkOrange w-[200px] py-2 rounded'>Send Message</button>
            </form>
        </div>
    </div>
  )
}

export default GetInTouchForm;