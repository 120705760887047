import React from "react";
import next from "../../assets/user/home/next-js.svg";
import react from "../../assets/user/home/React-icon.svg.png";
import css from "../../assets/user/home/css_logo-removebg-preview.png";
import vue from "../../assets/user/home/logo_Vue.png";
import angular from "../../assets/user/home/Angular_logo.png";
import javascript from "../../assets/user/home/javascript-logo-removebg-preview.png";
import nodejs from "../../assets/user/home/pngegg.png";
import html from "../../assets/user/home/Html_logo.png";
import botstrap from "../../assets/user/home/Bootstrap.png";
import TailwindCss from "../../assets/user/home/tailwindcss-logo.png";
import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceIn,
  motionVariantsBounceOut,
} from "../common/animations";

const Frontend = () => {
  const images = [
    { src: html, alt: "html" },
    { src: next, alt: "NextJs" },
    { src: css, alt: "css" },
    { src: react, alt: "React" },
    { src: javascript, alt: "Javascript" },
    { src: vue, alt: "Vue" },
    { src: angular, alt: "Angular" },
    { src: nodejs, alt: "Nodejs" },
    { src: botstrap, alt: "botstrap" },
    { src: TailwindCss, alt: "TailwindCss" },
  ];
  return (
    <div className="flex flex-wrap justify-center gap-16 overflow-hidden">
      {images.map((image, index) => (
        <div key={index} className="">
          <motion.img
            variants={
              index % 2 == 0 ? motionVariantsBounceIn : motionVariantsBounceOut
            }
            {...motionProperties}
            src={image.src}
            alt={image.alt}
            className="w-[8rem] h-[7rem] object-contain"
          />
        </div>
      ))}
    </div>
  );
};

export default Frontend;
