import React from "react";
import website_web_development from "../../assets/user/home/website_web_development.svg";
import website_web_app_technology from "../../assets/user/home/website_web_app_technology.svg";
import flutter from "../../assets/user/home/flutter.svg";
import react_native from "../../assets/user/home/react_native.svg";
import android_ios from "../../assets/user/home/android_ios.svg";
import UxDesign from "../../assets/user/home/uiux-3d.png";
import ux_technology from "../../assets/user/home/ux_technology.svg";
import ionic from "../../assets/user/home/ionic.png";
import { motion } from "framer-motion";

import {
  motionProperties,
  motionVariantsBounceOut,
  motionVariantsScaleUp,
  motionVariantsRight,
  motionVariantsFadeIn,
  motionVariantsTop,
  motionVariantsLeft,
} from "../common/animations";

const OurServices = () => {
  return (
    <div className="lg:px-16 md:px-10 px-5 py-5 overflow-hidden ">
      <div className="tracking-wider flex flex-col gap-4">
        <motion.p
          variants={motionVariantsScaleUp}
          {...motionProperties}
          className="text-center text-primaryLightOrange"
        >
          Our Services
        </motion.p>
        <motion.h1
          variants={motionVariantsBounceOut}
          {...motionProperties}
          className="lg:text-4xl md:text-2xl text-xl text-center text-primaryTextColor"
        >
          Check What Services We Provide
        </motion.h1>
        <motion.p
          variants={motionVariantsFadeIn}
          {...motionProperties}
          className="text-center m-auto text-sm lg:w-[60%] text-secondaryTextColor"
        >
          Empowering businesses with cutting-edge technology, our comprehensive
          software solutions encompass development, design, consulting, and
          steadfast support. We specialize in tailoring each solution to meet
          diverse business needs, prioritizing innovation and unwavering
          commitment to quality at every step
        </motion.p>
      </div>
      <div className="mt-8 flex flex-col gap-10 tracking-wider">
        <div className="grid lg:grid-cols-2 gap-14 grid-cols-1 items-center">
          <motion.div
            variants={motionVariantsRight}
            {...motionProperties}
            className="flex flex-col gap-2"
          >
            <h1 className="lg:text-3xl text-xl">
              Web Development
            </h1>
            <div className="h-1 bg-gradient-to-r from-primaryDarkOrange to-white"></div>
            <p className="text-secondaryTextColor text-sm leading-7">
              At WebBocket, we offer top-notch web development services that
              cater to the unique needs of businesses of all sizes.Our team of
              skilled developers is equipped with the latest tools and
              technologies to build fast, secure, and user- friendly websites
              that drive results.
            </p>
            <motion.img
              src={website_web_app_technology}
              alt="website_web_app_technology"
              className="my-4"
              variants={motionVariantsFadeIn}
              {...motionProperties}
            />
            <button className="w-[200px] py-2 border-2 border-primaryLightOrange rounded text-primaryLightOrange hover:bg-primaryLightOrange hover:text-white">
              Know More
            </button>
          </motion.div>
          <motion.div
            variants={motionVariantsLeft}
            {...motionProperties}
            className="flex lg:justify-end"
          >
            <img
              src={website_web_development}
              alt="website_web_development img"
              className="w-full"
            />
          </motion.div>
        </div>

        <div className="lg:grid lg:grid-cols-2 gap-14 flex flex-col-reverse items-center">
          <motion.div
            variants={motionVariantsLeft}
            {...motionProperties}
            className="flex lg:justify-start"
          >
            <img
              src={android_ios}
              alt="website_web_development img"
              className="w-full rounded-xl"
            />
          </motion.div>
          <motion.div
            variants={motionVariantsRight}
            {...motionProperties}
            className="flex flex-col gap-2"
          >
            <h1 className="lg:text-3xl text-xl">
              Android & iOS App Development
            </h1>
            <div className="h-1 bg-gradient-to-r from-primaryDarkOrange to-white"></div>
            <p className="text-secondaryTextColor text-sm leading-7">
              WebBocket offers top-notch app development services that will help
              your business grow and stand out in the competitive mobile app
              market. Experience the best of both worlds with our hybrid app
              development service, combining the power of native apps with the
              flexibility of web-based technologies to deliver a seamless user
              experience across multiple platforms.
            </p>
            <div className="flex items-center gap-4 my-4">
              <motion.img
                src={flutter}
                alt="flutter"
                className=""
                variants={motionVariantsScaleUp}
                {...motionProperties}
              />
              <motion.img
                src={react_native}
                alt="native"
                className="md:w-[180px] w-[150px]"
                variants={motionVariantsScaleUp}
                {...motionProperties}
              />
              <motion.img
                src={ionic}
                alt="ionic"
                className="w-[110px]"
                variants={motionVariantsScaleUp}
                {...motionProperties}
              />
            </div>
            <button className="w-[200px] py-2 border-2 border-primaryLightOrange rounded text-primaryLightOrange hover:bg-primaryLightOrange hover:text-white">
              Know More
            </button>
          </motion.div>
        </div>

        <div className="grid lg:grid-cols-2 gap-14 grid-cols-1 items-center">
          <motion.div
            variants={motionVariantsRight}
            {...motionProperties}
            className="flex flex-col gap-2"
          >
            <h1 className="lg:text-3xl text-xl">Ui / Ux Design</h1>
            <div className="h-1 bg-gradient-to-r from-primaryDarkOrange to-white w-[40%]"></div>
            <p className="text-secondaryTextColor text-sm leading-7">
              Unlock the power of user-centric design with our UI/UX expertise,
              tailored to bring your software to life and drive user engagement.
              Experience a seamless digital journey with our intuitive UI/UX
              design, crafted to elevate your software and enhance user
              satisfaction.
            </p>
            <motion.img
              src={ux_technology}
              alt="website_web_app_technology"
              className="my-4"
              variants={motionVariantsFadeIn}
              {...motionProperties}
            />
            <button className="w-[200px] py-2 border-2 border-primaryLightOrange rounded text-primaryLightOrange hover:bg-primaryLightOrange hover:text-white">
              Know More
            </button>
          </motion.div>
          <motion.div
            variants={motionVariantsLeft}
            {...motionProperties}
            className="flex lg:justify-end"
          >
            <img
              src={UxDesign}
              alt="website_web_development img"
              className="w-full rounded-xl"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
