import React from "react";
import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceIn,
  motionVariantsSlipRight,
  motionVariantsSlipTop,
} from "../common/animations";
import { MdCheckCircle } from "react-icons/md";
import about_comapany from "../../assets/user/about/about_comapany.svg";

const AboutCompany = () => {
  return (
    <div>
      <div className="grid lg:grid-cols-2 grid-cols-1 mt-10 overflow-hidden">
        <motion.div
          variants={motionVariantsBounceIn}
          {...motionProperties}
          className="h-auto"
        >
          <img src={about_comapany} alt="our prod" className="w-full" />
        </motion.div>
        <motion.div
          variants={motionVariantsSlipRight}
          {...motionProperties}
          className="flex items-center"
        >
          <div>
            <p className="text-primaryLightOrange">About Company</p>
            <h1 className="md:text-4xl text-xl font-medium mt-2 md:w-[80%] leading-[1.2]">
              We Provide IT Solutions that Help you Succeed
            </h1>
            <p className="mt-4 text-secondaryTextColor tracking-wider leading-8">
              WebBocket is a leading provider of innovative IT solutions aimed
              at helping businesses thrive in the digital age. With a team of
              highly skilled professionals and a commitment to excellence, we
              deliver cutting-edge technology solutions tailored to the unique
              needs of our clients.
            </p>
            <div className="mt-3 flex flex-col gap-4">
              <div className="flex gap-2">
                <div>
                  <MdCheckCircle
                    size={20}
                    className="mt-[2px] text-primaryLightOrange"
                  />
                </div>
                <div>
                  <h1 className="font-medium">Seamless Management</h1>
                  <p className="text-sm tracking-wider leading-7 mt-1 md:text-left text-justify">
                    Our solutions enable seamless management of business
                    operations, allowing organizations to streamline processes
                    and enhance productivity.
                  </p>
                </div>
              </div>
              <div className="flex gap-2">
                <div>
                  <MdCheckCircle
                    size={20}
                    className="mt-[2px] text-primaryLightOrange"
                  />
                </div>
                <div>
                  <h1 className="font-medium">Flawless Incorporation</h1>
                  <p className="text-sm tracking-wider leading-7 mt-1 md:text-left text-justify">
                    We ensure flawless incorporation of our solutions into
                    existing systems, minimizing disruptions and maximizing
                    efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutCompany;
