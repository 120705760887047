import React from 'react';

const NonAuthLayout = (props) => {
    //   let userRole = decryptData()?.user?.role;

    //   if (localStorage.getItem("encryptedData")) {
    //     return (
    //       <Navigate to={{ pathname: `/${userRole}` }} />
    //     );
    //   }

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

export default NonAuthLayout