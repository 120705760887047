import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import bg from "../../assets/user/home/CountSection_bg_image.png";
import { motionProperties, motionVariantsRight, motionVariantsScaleUp, motionVariantsTop } from "../common/animations";

const CountSection = () => {
  const [visit, setVisit] = useState(false);
  const [hasCountStarted, setHasCountStarted] = useState(false);
  const [projectCount, setProjectCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [productCount, setProductCount] = useState(0);

  const countRef = useRef(null);

  const animateCount = (targetCount, setCount, intervalDuration) => {
    let currentCount = 0;
    const interval = setInterval(() => {
      if (currentCount < targetCount) {
        setCount((prevCount) => prevCount + 1);
        currentCount++;
      } else {
        clearInterval(interval);
      }
    }, intervalDuration);
  };

  const animateProjectCount = (targetCount) => {
    animateCount(targetCount, setProjectCount, 50);
  };

  const animateClientCount = (targetCount) => {
    animateCount(targetCount, setClientCount, 150);
  };

  const animateReviewCount = (targetCount) => {
    animateCount(targetCount, setReviewCount, 150);
  };

  const animateProductCount = (targetCount) => {
    animateCount(targetCount, setProductCount, 150);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisit(true);
        } else {
          setVisit(false);
        }
      });
    }, options);

    if (countRef.current) {
      observer.observe(countRef.current);
    }

    return () => {
      if (countRef.current) {
        observer.unobserve(countRef.current);
      }
    };
  }, []);
  
  useEffect(() => {
    if (visit && !hasCountStarted) {
      animateProjectCount(50);
      animateClientCount(40);
      animateReviewCount(30);
      animateProductCount(3);
      setHasCountStarted(true); 
    }
  }, [visit, hasCountStarted]);

  return (
    <div
      ref={countRef}
      className="lg:px-16 md:px-10 px-5 py-10 overflow-hidden"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="grid md:grid-cols-4 grid-cols-2 text-center md:p-4 md:gap-0 gap-4">
        <motion.div variants={motionVariantsRight} {...motionProperties}>
          <h1 className="md:text-4xl text-2xl text-white font-bold">
            {projectCount}+
          </h1>
          <p className="text-white mt-4">Project Finished</p>
        </motion.div>

        <motion.div variants={motionVariantsTop} {...motionProperties}>
          <h1 className="md:text-4xl text-2xl text-white font-bold">
            {clientCount}+
          </h1>
          <p className="text-white mt-4">Happy Clients</p>
        </motion.div>

        <motion.div variants={motionVariantsRight} {...motionProperties}>
          <h1 className="md:text-4xl text-2xl text-white font-bold">
            {reviewCount}+
          </h1>
          <p className="text-white mt-4">Team</p>
        </motion.div>

        <motion.div variants={motionVariantsTop} {...motionProperties}>
          <h1 className="md:text-4xl text-2xl text-white font-bold">
            {productCount}+
          </h1>
          <p className="text-white mt-4">Products</p>
        </motion.div>
      </div>
    </div>
  );
};

export default CountSection;
