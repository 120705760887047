import React from 'react'
import Banner from '../../components/home/Banner';
import WhyChooseUs from '../../components/home/WhyChooseUs';
import OurServices from '../../components/home/OurServices';
import CountSection from '../../components/home/CountSection';
import OurProducts from '../../components/home/OurProducts';
import OurTestimonials from '../../components/home/OurTestimonials';
import TechnologyUse from '../../components/home/TechnologyUse';
import TechnologyNews from '../../components/home/TechnologyNews';
import OurClients from '../../components/home/OurClients';
import EmailBanner from '../../components/home/EmailBanner';

const Home = () => {
  return (
    <div>
        <Banner/>
        <WhyChooseUs/>
        <OurServices/>
        <CountSection/>
        <OurProducts/>
        <OurTestimonials/>
        <TechnologyUse/>
        <TechnologyNews/>
        <OurClients/>
        <EmailBanner/>
    </div>
  )
}

export default Home;