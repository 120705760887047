import React, { useState } from "react";
import node from "../../assets/user/home/NodeJS.svg";
import php from "../../assets/user/home/PHP.svg";
import mySql from "../../assets/user/home/mySquil.svg";
import java from "../../assets/user/home/Java.svg";
import net from "../../assets/user/home/NETCore.svg";
import python from "../../assets/user/home/Python.svg";
import rail from "../../assets/user/home/RubyOnRails.svg";
import go from "../../assets/user/home/Go.svg";
import mongo from "../../assets/user/home/mongo.svg";
import Frontend from "./Frontend";
import Database from "./Database";
import Devops from "./Devops";
import Cloudtesting from "./Cloudtesting";
import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceIn,
  motionVariantsBounceOut,
} from "../common/animations";

const TechnologyUse = () => {
  const [activeTab, setActiveTab] = useState("Backend");

  const renderContent = () => {
    switch (activeTab) {
      case "Backend":
        return (
          <div>
            <Backend />
          </div>
        );
      case "Frontend":
        return (
          <div>
            <Frontend />
          </div>
        );
      case "Databases":
        return (
          <div>
            <Database />
          </div>
        );
      case "CMS":
        return <div>Content for CMS</div>;
      case "CloudTesting":
        return (
          <div>
            <Cloudtesting />
          </div>
        );
      case "DevOps":
        return (
          <div>
            <Devops />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="lg:px-16 md:px-10 px-5 py-5 overflow-hidden">
      <div className="tracking-wider flex flex-col gap-4">
        <p className="text-center text-primaryLightOrange">Technology Use</p>
        <h1 className="lg:text-4xl md:text-2xl text-xl text-center text-primaryTextColor">
          Our Tech Stack
        </h1>
      </div>
      <motion.div
        variants={motionVariantsBounceIn}
        {...motionProperties}
        className="flex flex-wrap justify-center gap-2 my-4"
      >
        <button
          className={`px-4 py-2 ${
            activeTab === "Backend"
              ? "bg-primaryLightOrange text-white"
              : "bg-gray-200 text-black"
          }`}
          onClick={() => setActiveTab("Backend")}
        >
          Backend
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === "Frontend"
              ? "bg-primaryLightOrange text-white"
              : "bg-gray-200 text-black"
          }`}
          onClick={() => setActiveTab("Frontend")}
        >
          Frontend
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === "Databases"
              ? "bg-primaryLightOrange text-white"
              : "bg-gray-200 text-black"
          }`}
          onClick={() => setActiveTab("Databases")}
        >
          Databases
        </button>
        {/* <button
          className={`px-4 py-2 ${
            activeTab === "CMS"
              ? "bg-primaryLightOrange text-white"
              : "bg-gray-200 text-black"
          }`}
          onClick={() => setActiveTab("CMS")}
        >
          CMS
        </button> */}
        <button
          className={`px-4 py-2 ${
            activeTab === "CloudTesting"
              ? "bg-primaryLightOrange text-white"
              : "bg-gray-200 text-black"
          }`}
          onClick={() => setActiveTab("CloudTesting")}
        >
          CloudTesting
        </button>
        <button
          className={`px-4 py-2 ${
            activeTab === "DevOps"
              ? "bg-primaryLightOrange text-white"
              : "bg-gray-200 text-black"
          }`}
          onClick={() => setActiveTab("DevOps")}
        >
          DevOps
        </button>
      </motion.div>
      <div className="mt-4">{renderContent()}</div>
    </div>
  );
};

export default TechnologyUse;

const Backend = () => {
  const images = [
    { src: node, alt: "NodeJS" },
    { src: php, alt: "PHP" },
    { src: mySql, alt: "MySQL" },
    { src: java, alt: "Java" },
    { src: net, alt: ".NET Core" },
    { src: python, alt: "Python" },
    { src: rail, alt: "Ruby on Rails" },
    // { src: go, alt: "Go" },
    { src: mongo, alt: "MongoDB" },
  ];

  return (
    <div className="flex flex-wrap justify-center gap-4">
      {images.map((image, index) => (
        <div key={index} className="">
          <motion.img
            variants={
              index % 2 == 0 ? motionVariantsBounceIn : motionVariantsBounceOut
            }
            {...motionProperties}
            src={image.src}
            alt={image.alt}
            className="w-full h-full object-contain"
          />
        </div>
      ))}
    </div>
  );
};
