import website_icon from "../../assets/user/service/website_icon.svg";
import android_icon from "../../assets/user/service/android_icon.svg";
import ui_us_icon from "../../assets/user/service/ui_us_icon.svg";
import crm_icon from "../../assets/user/service/crm_icon.svg";
import pos_icon from "../../assets/user/service/pos_icon.svg";
import building_icon from "../../assets/user/service/building_icon.svg";
import programmingBG from "../../assets/user/programming-background.png";
import website_de_icon from "../../assets/user/service/webisteDev_icon.svg";
import webiste_mentainace from "../../assets/user/service/webiste_mentainace.svg";
import onTime_service_icon from "../../assets/user/service/onTime_service_icon.svg";

import uiUxrepresentations from '../../assets/user/service/uiUxrepresentations.png'
import android_image from '../../assets/user/service/android_image.jpeg'
import crm_image from '../../assets/user/service/crm_image.jpeg'
import posSoftware from '../../assets/user/service/pos_image.png'
import BillingSoftware from '../../assets/user/service/billing_image.jpeg'
import wbsite_and_app from '../../assets/user/service/wbsite_and_app.jpeg'

const servicesData = [
  {
    id: 1,
    title: "Website & Webapp Development",
    description: "At WebBocket, we offer top-notch web development services that cater to the unique needs of businesses of all sizes. Our team of skilled developers builds fast, secure, and user-friendly websites to drive results.",
    icon: website_icon,
    image: wbsite_and_app,
    whatWeProvide: [
      {
        icon: website_de_icon,
        title: "Custom Web Development",
        description: "Tailored web solutions to meet your business needs.",
      },
      {
        icon: website_de_icon,
        title: "E-commerce Solutions",
        description: "Comprehensive e-commerce platforms for online businesses.",
      },
      {
        icon: webiste_mentainace,
        title: "Content Management Systems",
        description: "User-friendly CMS for easy website management.",
      },
      {
        icon: onTime_service_icon,
        title: "Responsive Design",
        description: "Websites that look great on all devices.",
      },
    ],
    faq: [
      {
        id: 1,
        question: "What technologies do you use?",
        answer: "We use the latest technologies including React, Node.js, and more.",
      },
      {
        id: 2,
        question: "How long does it take to build a website?",
        answer: "Typically, it takes around 4-6 weeks depending on the complexity.",
      },
      {
        id: 3,
        question: "Do you provide maintenance services?",
        answer: "Yes, we offer ongoing maintenance and support for all websites we develop.",
      },
      {
        id: 4,
        question: "Can you help with SEO?",
        answer: "Yes, we provide SEO services to help your website rank higher in search engines.",
      },
    ],
    AboutService: "Our web development services include a full range of solutions tailored to your business.",
    AboutServiceKeyPoint: [
      "Improve productivity and performance",
      "Hassle-free setup & management",
      "Reduce your service costs",
      "User-friendly admin & reporting features",
      "Lifetime license, No monthly or yearly fee",
      "Get peace of mind knowing your services are in trusted hands.",
    ],
  },
  {
    id: 2,
    title: "Android & iOS App Development",
    description: "Develop high-quality mobile applications for Android and iOS platforms with WebBocket. We ensure your app stands out in the competitive market, providing a seamless user experience.",
    icon: android_icon,
    image: android_image,
    whatWeProvide: [
      {
        icon: website_de_icon,
        title: "Native App Development",
        description: "Robust and high-performance native apps.",
      },
      {
        icon: website_de_icon,
        title: "Cross-Platform App Development",
        description: "Efficient solutions for both Android and iOS platforms.",
      },
      {
        icon: webiste_mentainace,
        title: "UI/UX Design for Mobile Apps",
        description: "Intuitive and engaging mobile app interfaces.",
      },
      {
        icon: onTime_service_icon,
        title: "App Maintenance and Support",
        description: "Ongoing support and updates for your mobile apps.",
      },
    ],
    faq: [
      {
        id: 1,
        question: "Do you develop native apps?",
        answer: "Yes, we specialize in native app development for both Android and iOS.",
      },
      {
        id: 2,
        question: "How much does app development cost?",
        answer: "The cost varies depending on the features and complexity of the app.",
      },
      {
        id: 3,
        question: "What is the typical development time for a mobile app?",
        answer: "It usually takes 8-12 weeks to develop a mobile app, depending on the scope.",
      },
      {
        id: 4,
        question: "Do you offer post-launch support?",
        answer: "Yes, we provide comprehensive post-launch support and maintenance.",
      },
    ],
    AboutService: "Our mobile app development services ensure your app stands out in the crowded market.",
    AboutServiceKeyPoint: [
      "High-quality native and cross-platform apps",
      "User-friendly interfaces",
      "Optimized performance",
      "Comprehensive post-launch support",
      "Regular updates and maintenance",
    ],
  },
  {
    id: 3,
    title: "Ui & Ux Design",
    description: "Create stunning and intuitive user interfaces and experiences with WebBocket's UI/UX design services. Our designs are focused on user satisfaction and seamless digital journeys.",
    icon: ui_us_icon,
    image: uiUxrepresentations,
    whatWeProvide: [
      {
        icon: website_de_icon,
        title: "User Interface Design",
        description: "Attractive and functional UI designs.",
      },
      {
        icon: website_de_icon,
        title: "User Experience Design",
        description: "Enhancing user satisfaction with improved usability.",
      },
      {
        icon: webiste_mentainace,
        title: "Prototyping and Wireframing",
        description: "Interactive prototypes and wireframes for your projects.",
      },
      {
        icon: onTime_service_icon,
        title: "Usability Testing",
        description: "Testing designs to ensure they meet user needs.",
      },
    ],
    faq: [
      {
        id: 1,
        question: "What tools do you use for UI/UX design?",
        answer: "We use industry-standard tools like Figma, Sketch, and Adobe XD.",
      },
      {
        id: 2,
        question: "Can you redesign existing interfaces?",
        answer: "Yes, we offer redesign services to improve existing interfaces.",
      },
      {
        id: 3,
        question: "Do you conduct user research?",
        answer: "Yes, we conduct thorough user research to inform our design process.",
      },
      {
        id: 4,
        question: "How long does a typical design project take?",
        answer: "A typical design project can take 4-8 weeks, depending on the scope.",
      },
    ],
    AboutService: "Our UI/UX design services are focused on creating engaging and effective user experiences.",
    AboutServiceKeyPoint: [
      "Innovative design solutions",
      "User-centered approach",
      "High fidelity prototypes",
      "Seamless user experiences",
      "Consistent branding",
    ],
  },
  {
    id: 4,
    title: "CRM Software",
    description: "Enhance your customer relationships with our robust CRM software solutions. We offer tailored CRM development and integration services to boost your sales and customer support.",
    icon: crm_icon,
    image: crm_image,
    whatWeProvide: [
      {
        icon: website_de_icon,
        title: "Custom CRM Development",
        description: "Tailored CRM solutions to fit your business needs.",
      },
      {
        icon: website_de_icon,
        title: "Integration Services",
        description: "Seamlessly integrate CRM with your existing systems.",
      },
      {
        icon: webiste_mentainace,
        title: "Sales Automation",
        description: "Automate your sales processes to improve efficiency.",
      },
      {
        icon: onTime_service_icon,
        title: "Customer Support Management",
        description: "Manage customer support tickets and inquiries effectively.",
      },
    ],
    faq: [
      {
        id: 1,
        question: "Can CRM software be customized?",
        answer: "Yes, we provide custom CRM solutions tailored to your requirements.",
      },
      {
        id: 2,
        question: "What are the benefits of using a CRM?",
        answer: "Improved customer relationships, increased sales, and better data management.",
      },
      {
        id: 3,
        question: "Is your CRM cloud-based?",
        answer: "Yes, we offer both cloud-based and on-premise CRM solutions.",
      },
      {
        id: 4,
        question: "Do you provide training for CRM software?",
        answer: "Yes, we offer comprehensive training and support for all our CRM solutions.",
      },
    ],
    AboutService: "Our CRM solutions are designed to help businesses manage their customer interactions effectively.",
    AboutServiceKeyPoint: [
      "Customizable features",
      "Seamless integration",
      "Enhanced customer insights",
      "Improved data management",
      "Scalable solutions",
    ],
  },
  {
    id: 5,
    title: "POS Software",
    description: "Optimize your retail operations with our advanced POS software solutions. Our POS systems include sales tracking, inventory management, and customer loyalty programs.",
    icon: pos_icon,
    image: posSoftware,
    whatWeProvide: [
      {
        icon: website_de_icon,
        title: "Retail POS Systems",
        description: "Comprehensive POS solutions for retail businesses.",
    },
    {
      icon: website_de_icon,
      title: "Inventory Management",
      description: "Efficient inventory management features.",
    },
    {
      icon: webiste_mentainace,
      title: "Sales Reporting",
      description: "Detailed sales reports to track your business performance.",
    },
    {
      icon: onTime_service_icon,
      title: "Customer Management",
      description: "Manage customer data and loyalty programs.",
    },
  ],
  faq: [
    {
      id: 1,
      question: "What features are included in the POS software?",
      answer: "Our POS software includes sales tracking, inventory management, and customer management.",
    },
    {
      id: 2,
      question: "Is the POS software cloud-based?",
      answer: "Yes, our POS solutions can be cloud-based or on-premise based on your needs.",
    },
    {
      id: 3,
      question: "Can the POS system be integrated with other software?",
      answer: "Yes, we offer integration with various accounting, CRM, and e-commerce platforms.",
    },
    {
      id: 4,
      question: "Do you provide hardware for POS systems?",
      answer: "Yes, we offer a range of hardware options to complement our POS software.",
    },
  ],
  AboutService: "Our POS software is designed to streamline retail operations and enhance efficiency.",
  AboutServiceKeyPoint: [
    "Easy-to-use interface",
    "Comprehensive features",
    "Real-time data analytics",
    "Scalable and flexible",
    "Reliable and secure",
  ],
},
{
  id: 6,
  title: "Billing Software",
  description: "Simplify your billing process with our efficient billing software solutions. Our automated billing systems ensure accurate invoicing and compliance with tax regulations.",
  icon: building_icon,
  image: BillingSoftware,
  whatWeProvide: [
    {
      icon: website_de_icon,
      title: "Automated Billing",
      description: "Automate your billing processes and reduce errors.",
    },
    {
      icon: website_de_icon,
      title: "Invoice Management",
      description: "Efficiently manage your invoices and payments.",
    },
    {
      icon: webiste_mentainace,
      title: "Subscription Billing",
      description: "Manage recurring billing for subscription-based services.",
    },
    {
      icon: onTime_service_icon,
      title: "Tax Compliance",
      description: "Ensure compliance with tax regulations and automate tax calculations.",
    },
  ],
  faq: [
    {
      id: 1,
      question: "Can the billing software be integrated with other systems?",
      answer: "Yes, our billing software can be integrated with various other systems.",
    },
    {
      id: 2,
      question: "Does the software support multiple currencies?",
      answer: "Yes, it supports multiple currencies for global businesses.",
    },
    {
      id: 3,
      question: "Is the billing software customizable?",
      answer: "Yes, we offer customization options to fit your specific business needs.",
    },
    {
      id: 4,
      question: "Do you provide training for using the billing software?",
      answer: "Yes, we offer training sessions to help you get started with our billing software.",
    },
  ],
  AboutService: "Our billing software is designed to simplify and streamline your billing processes.",
  AboutServiceKeyPoint: [
    "Automated processes",
    "Accurate invoicing",
    "Multiple currency support",
    "User-friendly interface",
    "Secure and reliable",
  ],
},
];

export default servicesData;

