import React, { useEffect, useState } from 'react';
import Navbar from '../components/common/Navbar';
import Sidebar from '../components/common/Sidebar';
import AdminNavbar from '../components/common/AdminNavbar';

const DashboardLayout = ({ children }) => {
    const [openSidebar, setOpenSidebar] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 769) {
                setOpenSidebar(true);
            } else {
                setOpenSidebar(false);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="flex">
            <div
                className={`${
                    openSidebar ? 'relatice' : 'hidden'
                }`}
            >
                <Sidebar setOpenSidebar={setOpenSidebar} />
            </div>
            <div className="w-full">
                <AdminNavbar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
                <div className='p-4'>
                    <main>{children}</main>
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;
