import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  const { login } = useAuth();

  const handleLogin = () => {
    login();
    navigate('/dashboard');
  };

  return (
    <div className='w-full text-center p-5'>
      <h1>Login Page</h1>
      <button
        className='bg-blue-400 p-6'
        onClick={handleLogin}>
        Log In
      </button>
    </div>
  );
};

export default Login;
