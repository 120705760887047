// src/components/ImageSlider.js

import React from 'react';
import './ImageSlider.css';

const ImageSlider = ({ images }) => {
  return (
    <div className="slider h-auto overflow-hidden relative w-full mx-auto mt-10">
      <div className="slide-track flex animate-scroll">
        {images.concat(images).map((image, index) => (
          <div key={index} className="slide w-auto h-auto flex-shrink-0">
            <img src={image.src} alt={image.alt} className="w-[50%] h-full scal" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
