import React, { useState, useEffect } from "react";
import axios from "axios";

const ContactModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.classList.contains("fixed")) {
        handleClose();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [show, handleClose]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.message
    ) {
      setError("Please fill out all fields.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:8000/webbocket/inquiry/create-inquiry",
        formData
      );
      setLoading(false);
      setSuccessMsg("Your inquiry has been submitted successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      console.log(response)
      setError(""); // Clear any previous error messages
      setTimeout(() => {
        setSuccessMsg("Your inquiry has been submitted successfully!");
        handleClose();
      }, 3000); // Close modal after 3 seconds
    } catch (error) {
      console.error("Error submitting inquiry:", error);
      setError("Error submitting inquiry. Please try again later.");
      setLoading(false);
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center overflow-y-auto ${
        show ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
      style={{ transition: "opacity 0.3s" }}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div
        className="relative backdrop-blur-lg md:w-[500px] w-[90%] rounded-lg p-8  mx-auto transform transition-all duration-300 text-white border-2 border-white"
        style={{ marginTop: show ? "0" : "-20vh" }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold" id="modal-title">
            Inquiry Form
          </h2>
          <button
            onClick={handleClose}
            className="hover:mb-1 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="roundtext-black"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {successMsg && <div className="text-green-500 mb-4">{successMsg}</div>}
        <hr className="my-4" />
        <form onSubmit={handleSubmit}>
          {/* Name */}
          <div className="mb-4">
            <label htmlFor="name" className="block mb-1 font-medium">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="border border-gray-400 rounded px-2 py-2 outline-none w-full text-black"
              required
            />
          </div>
          {/* Email */}
          <div className="mb-4">
            <label htmlFor="email" className="block mb-1 font-medium">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="border border-gray-400 rounded px-2 py-2 outline-none w-full text-black"
              required
            />
          </div>
          {/* phone */}
          <div className="mb-4">
            <label htmlFor="phone" className="block mb-1 font-medium">
              Phone No
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="border border-gray-400 rounded px-2 py-2 outline-none w-full text-black"
              required
            />
          </div>
          {/* Message */}
          <div className="mb-4">
            <label htmlFor="message" className="block mb-1 font-medium">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="border border-gray-400 rounded px-2 py-2 outline-none w-full text-black"
              required
            ></textarea>
          </div>
          <hr className="my-4" />
          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-gradient-to-r from-primaryDarkOrange to-primaryLightOrange textwhite px-4 py-2 rounded w-[200px]"
              disabled={loading}
            >
              {loading ? (
               "Loading..."
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
        {/* Error Message */}
        {error && <div className="text-red-500 mt-2">{error}</div>}
      </div>
    </div>
  );
};

export default ContactModal;
