import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleRegister = () => {
        login();
        navigate('/dashboard');
    };

    return (
        <div className='w-full text-center p-5'>
            <h1>Register Page</h1>
            <button
                className='bg-blue-400 p-6'
                onClick={handleRegister}>
                Register
            </button>
        </div>
    );
};

export default Register;
