// Motion Variants
export const motionVariantsTop = {
  hidden: { opacity: 0, y: -40 },
  visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 50 } }
};

export const motionVariantsLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 50 } }
};

export const motionVariantsRight = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 50 } }
};

export const motionVariantsSlipTop = {
  hidden: { opacity: 0, y: -40 },
  visible: { opacity: 1, y: 0, transition: { type: 'tween', duration: 0.8 } },
};

export const motionVariantsSlipLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { type: 'tween', duration: 0.8 } },
};

export const motionVariantsSlipRight = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { type: 'tween', duration: 0.8 } },
};

export const motionVariantsRotateIn = {
  hidden: { opacity: 0, rotate: -180 },
  visible: { opacity: 1, rotate: 0, transition: { type: 'spring', stiffness: 100 } },
};

export const motionVariantsRotateOut = {
  hidden: { opacity: 0, rotate: 180 },
  visible: { opacity: 1, rotate: 0, transition: { type: 'spring', stiffness: 100 } },
};

export const motionVariantsScaleUp = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { type: 'spring', stiffness: 200 } },
};

export const motionVariantsScaleDown = {
  hidden: { opacity: 0, scale: 1.5 },
  visible: { opacity: 1, scale: 1, transition: { type: 'spring', stiffness: 200 } },
};

export const motionVariantsFadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};

export const motionVariantsFadeOut = {
  hidden: { opacity: 1 },
  visible: { opacity: 0, transition: { duration: 1 } },
};

export const motionVariantsBounceIn = {
  hidden: { opacity: 0, y: -100 },
  visible: { opacity: 1, y: 0, transition: { type: 'spring', damping: 5 } },
};

export const motionVariantsBounceOut = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0, transition: { type: 'spring', damping: 5 } },
};

export const motionVariantsCardFlip = {
  hidden: { opacity: 0, rotateY: 180 },
  visible: { opacity: 1, rotateY: 0, transition: { type: 'spring', stiffness: 100 } },
};

export const motionVariantsZoomIn = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1, transition: { type: 'spring', stiffness: 200 } },
};

export const motionVariantsZoomOut = {
  hidden: { opacity: 0, scale: 2 },
  visible: { opacity: 1, scale: 1, transition: { type: 'spring', stiffness: 200 } },
};

export const motionVariantsSlideUpDown = {
  hidden: { opacity: 0, y: -100 },
  visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 100 } },
};

export const motionVariantsSlideLeftRight = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 100 } },
};

// Motion Properties
export const motionProperties = {
  initial: "hidden",
  whileInView: "visible",
  transition: { duration: 1, delay: 0.5 }
};

export const motionPropertiesLongTime = {
  initial: "hidden",
  whileInView: "visible",
  transition: { duration: 10, delay: 1.9 },
};

// Export more animations as needed
