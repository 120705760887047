import React from "react";
import browserstack from "../../assets/user/home/browserstack_logo.png";
import akamai from "../../assets/user/home/akamai_logo.png";
import { motion } from "framer-motion";
import {
  motionProperties,
  motionVariantsBounceIn,
  motionVariantsBounceOut,
} from "../common/animations";

const Cloudtesting = () => {
  const images = [
    { src: browserstack, alt: "browserstack" },
    { src: akamai, alt: "akamai" },
  ];
  return (
    <div className="flex flex-wrap justify-center gap-16">
      {images.map((image, index) => (
        <motion.div
          variants={
            index % 2 == 0 ? motionVariantsBounceIn : motionVariantsBounceOut
          }
          {...motionProperties}
          key={index}
          className=""
        >
          <img
            src={image.src}
            alt={image.alt}
            className="w-[8rem] h-[7rem] object-contain"
          />
        </motion.div>
      ))}
    </div>
  );
};

export default Cloudtesting;
