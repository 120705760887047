import React from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';

const AuthLayout = (props) => {
    //   let userRole = decryptData()?.user?.role;

    //   if (localStorage.getItem("encryptedData")) {
    //     return (
    //       <Navigate to={{ pathname: `/${userRole}` }} />
    //     );
    //   }

    return (
        <React.Fragment>
            <DashboardLayout>
                {props.children}
            </DashboardLayout>
        </React.Fragment>
    )
}

export default AuthLayout