import React from "react";
import footer_black_bg from "../../assets/user/footer_black_bg.svg";
import logo from "../../assets/user/white_logo.svg";
import { FaFacebook, FaLinkedin, FaPinterest } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const UserFooter = () => {
  return (
    <div className="md:bg-transparent bg-black">
      <div
        style={{
          backgroundImage: `url(${footer_black_bg})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="h-auto mt-10 flex items-center lg:px-16 md:px-10 px-5"
      >
        <div className="w-full  text-white md:mt-[130px] mt-20">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-8">
            <div className="flex flex-col gap-4 px-4">
              <img src={logo} alt="logo" className="w-16" />
              <p className="font-light">
                We're a team of skilled professionals dedicated to delivering
                cutting-edge technology solutions, tailored to your unique
                business needs."
              </p>
              <div className="flex items-center gap-4">
                <div>
                  <FaFacebook size={22} />
                </div>
                <div>
                  <FaSquareXTwitter size={22} />
                </div>
                <div>
                  <FaLinkedin size={22} />
                </div>
                <div>
                  <FaPinterest size={22} />
                </div>
              </div>
            </div>
            <div className="px-4">
              <h1 className="text-xl">Quick Links</h1>
              <div className="flex flex-col gap-3 mt-6">
                <Link to='/' className="font-light">Home</Link>
                <Link to='/about-us' className="font-light">About Us</Link>
                <Link to='/services' className="font-light">Service</Link>
                <Link to='/contact-us' className="font-light">Contact Us</Link>
              </div>
            </div>
            <div className="px-4">
              <h1 className="text-xl">Contact Us</h1>
              <div className="flex flex-col gap-3 mt-6">
                <p className="font-light">
                  Kolathia Square, near sri sai garden, uco bank top floor,
                  khandagiri, Bhubaneswar, 751030
                </p>
                <p className="font-light">web.bocket@gamil.com</p>
                <p className="font-light">+91 83288 92097</p>
              </div>
            </div>
            <div className="px-4">
              <h1 className="text-xl">Subscribe</h1>
              <div className="flex flex-col gap-3 mt-6">
                <p className="font-light">
                  Follow our newsletter to stay updated about us.
                </p>
                <div className="flex rounded overflow-hidden">
                  <input
                    type="text"
                    placeholder="Type Your Email.."
                    className="w-[85%] outline-none px-4 py-1 text-black"
                  />
                  <button className="w-[15%] flex items-center justify-center bg-primaryDarkOrange py-3">
                    <IoIosArrowForward />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="mt-8 border-red-500" />

          <div className="text-center text-secondaryTextColor my-6">
            <p>2024 © . All Rights Reserved .</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFooter;
