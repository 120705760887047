import React from "react";
// import headingBanner_bg from "../../assets/user/headingBanner_bg.svg";
import headingBanner_bg from "../../assets/user/headingBanner.png";

const HeadingBanner = ({path,title}) => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${headingBanner_bg})`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="h-[350px] flex justify-center "
      >
        <div className="mt-[70px] text-center text-white tracking-wider">
          <h1 className="text-3xl font-medium">{title}</h1>
          <div className="mt-3 ">
            <span className="bg-[#ffffff63] px-6 py-1">{path}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadingBanner;
